import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  categoryCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.categoryCollection = this.afs.collection('categories');
  }

  getData(): Observable<any[]> {
    return this.categoryCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return {id: a.payload.doc.id, ...data};
        });
      })
    );
  }

  getCategory(id: string) {
    return this.afs.doc<any>(`categories/${id}`);
  }

  createCategory(data) {
    const category = JSON.parse(JSON.stringify(data));
    return this.categoryCollection.add(category);
  }

  updateCategory(id: string, data: any) {
    return this.getCategory(id).update(data);
  }

  deleteCategory(id: string) {
    return this.getCategory(id).delete();
  }
}
