import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { v4 } from 'uuid';

import { NotifyService } from 'src/app/core/notify.service';
import { MineLanguageService } from 'src/app/services/mine/mine-language.service';
import { Language } from 'src/app/models/language';

@Component({
  selector: 'language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  language: Language;
  dupLanguage: any;
  isLoading = false;

  constructor(
    private _location: Location,
    private notify: NotifyService,
    private mineLanguageService: MineLanguageService
  ) { 
    this.language = new Language();
    this.dupLanguage = JSON.parse(JSON.stringify(this.language));
  }

  ngOnInit(): void {
  }

  save(){

    this.dupLanguage.uuid = v4();
    this.isLoading = true;

    this.dupLanguage.title = this.language.title;
  
    this.mineLanguageService.createLanguage(this.dupLanguage)
    .then((result) => {
      this.isLoading = false;
      this.notify.update('Тил сакталды.', 'success');
      // this._location.back();
    }).catch();

  }

  onCancel() {
    this._location.back();
  }
}
