import { Component, OnInit } from '@angular/core';
import { ExamsService } from 'src/app/services/exams.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import { Student } from 'src/app/models/student';
import { ApiClientService } from 'src/app/shared/api-client.service';
import saveAs from 'file-saver';
// import {CategoryService} from '../../shared/category.service';
import { MineCategoryService } from 'src/app/services/mine/mine-category.service';
// import { Category } from 'src/app/models/category';
// import { MineAnswerkeysService } from 'src/app/services/mine/mine-answerkeys.service';

type UserFields = 'phone';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css']
})
export class ExamsComponent implements OnInit{
  // generateExcelLink = 'api/generate-exam-excel';
  generatePdfLink = 'api/generate-exam-pdf'
  mainPageLink = '';
  downloadLinkIsLoading = false;
  documentSubmitted = false;
  predmetter = ['math', 'analogiya', 'okuuTushunuu', 'grammatika'];

  phone:string = '';
  exams: Student[];
  loading: boolean = false;
  errorMessage;
  empty: boolean = false;
  selectedCategory: any;

  examsForm: FormGroup;
  formErrors: FormErrors = {
    'phone': ''
  };
  validationMessages = {
    'phone': {
      'required': 'Телефондун номерин жазыңыз.',
      'pattern': 'Телефондун номери сандар менен жазылат.',
      'minlength': 'Телефондун номери 10 сандан аз болбосун.',
      'maxlength': 'Телефондун номери 10 сандан көп болбосун.',
    }
  };
  
  constructor(
    private fb: FormBuilder,
    private examsService: ExamsService,
    private apiClientService: ApiClientService, 
    private mineCategoryService: MineCategoryService,
    // private mineAnswerKeysService: MineAnswerkeysService
    ) { }

  public getExams() {
    this.empty = false;
    const formValues = this.examsForm.value;
    this.loading = true;
    this.errorMessage = "";
    this.examsService.query({'phone': formValues['phone'] }).subscribe(
      (examResults) => {
        this.exams = examResults;
      },
      (error) => {                              //error() callback
        console.error('Request failed with error')
        this.errorMessage = error;
        this.loading = true;
      },
      () => {                                   //complete() callback
        // console.log('Request completed')      //This is actually not needed 
        this.loading = false; 
        if(this.exams.length == 0){
          this.empty = true;
        }
      });
  }

  ngOnInit(): void {
    this.buildForm();
  }

  public getIntensive(student){  
    
    // let categoryRes = student.categoryRes; 
    let math = student.categoryRes.math;
    let analogiya = student.categoryRes.analogiya;
    let okuuTushunuu = student.categoryRes.okuuTushunuu;
    let grammatika = student.categoryRes.grammatika;
    // categoryRes.math = undefined;
    // categoryRes.analogiya = undefined;
    // categoryRes.okuuTushunuu = undefined;
    // categoryRes.grammatika = undefined;

    this.getCategory(student,math,analogiya,okuuTushunuu,grammatika)
  }

  private getCategory(student,math,analogiya,okuuTushunuu,grammatika){
    this.mineCategoryService.queryCategoryForExam({'answerKeyUuid':student.selectedAnswerKeyUuid})
    .subscribe((data)=>{

      this.selectedCategory = data;
      const resStudents = this.evaluateByCategory([student]);
      this.submitToServerForPdf(resStudents, math,analogiya,okuuTushunuu,grammatika); 
      // this.submitToServerForExcel(resStudents, this.selectedCategory,categoryRes,math,analogiya,okuuTushunuu,grammatika); 
    });
          
  }

  private evaluateByCategory(students: Student[]) {
    students[0].points['math'] = [];
    students[0].points['analogiya'] = [];
    students[0].points['okuuTushunuu'] = [];
    students[0].points['grammatika'] = [];
    
    const evaluatedStudents = students.map((student) => {  
      const group = (student.group === 'K') ? 'kg' : 'ru';
      const studentCategory = JSON.parse(JSON.stringify(this.selectedCategory[0][group]));

      this.predmetter.forEach((boluk) => {
        Object.keys(this.selectedCategory[0][group][boluk]).forEach((predmet) => {
          this.selectedCategory[0][group][boluk][predmet].subCategories.forEach((cat, catInd) => {
            let total = 0;
            let correct = 0;
            cat.questions.forEach((val, ind) => {
              if (!cat.questions[ind] || !cat.questions[ind].length) {
                return;
              }
              total++;
              if (student.points[boluk][val - 1] === '1' || student.points[boluk][val - 1] === 1) {
                correct++;
              }
            });

            studentCategory[boluk][predmet].subCategories[catInd].questions = undefined;
            studentCategory[boluk][predmet].subCategories[catInd].total = total;
            studentCategory[boluk][predmet].subCategories[catInd].correct = correct;

          });
        });
      });

      student.categoryRes = studentCategory;
      student.createdAt = new Date();

      // points evaluation started
      const mathsEvaluate = [1, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 44, 45, 46, 47, 48, 49, 50, 51, 52, 54, 55, 56, 57, 58, 59, 60, 61, 63, 64, 65, 66, 67];
      const analogiyaEvaluate = [5, 8, 10, 12, 13, 16, 17, 19, 21, 23, 25, 27, 29, 30, 33, 34, 36, 38, 40, 42, 44, 45, 48, 49, 51, 53, 55, 57, 59, 61, 63];
      const okuuTushunuuEvaluate = [5, 7, 8, 10, 12, 14, 16, 17, 20, 21, 23, 25, 26, 29, 30, 32, 34, 35, 38, 39, 41, 43, 44, 47, 48, 50, 52, 54, 56, 57, 58];
      const grammatikaEvaluate = [5, 6, 7, 8, 10, 12, 14, 16, 17, 19, 21, 23, 25, 26, 28, 30, 31, 34, 35, 37, 39, 40, 42, 44, 46, 48, 49, 51, 53, 55, 57];

      student.mathsTotalPoints = mathsEvaluate[student.points.mathTotal];
      student.analogiyaTotalPoints = analogiyaEvaluate[student.points.analogiyaTotal];
      student.okuuTotalPoints = okuuTushunuuEvaluate[student.points.okuuTushunuuTotal];
      student.grammatikaTotalPoints = grammatikaEvaluate[student.points.grammatikaTotal];
      student.totalPoints = student.mathsTotalPoints + student.analogiyaTotalPoints + student.okuuTotalPoints + student.grammatikaTotalPoints;
      // points evaluation ends

      return student;
    });

    return evaluatedStudents;
  }

  private submitToServerForPdf(student: Student[],math:any,analogiya:any,okuuTushunuu:any,grammatika:any) {
    let result;
    if(student[0].group == 'K'){
      result = `Жыйынтык-${student[0].name+'_'+student[0].surname}.pdf`;
    }else{
      result = `Результат-${student[0].name+'_'+student[0].surname}.pdf`
    }
    this.downloadLinkIsLoading = true;

    this.apiClientService.post(this.generatePdfLink, {student,math,analogiya,okuuTushunuu,grammatika}, {
      responseType: 'blob',
      contentType: 'application/pdf'
    }).subscribe((data: any) => {
      saveAs(data, result);
      this.mainPageLink = data.link;
      this.downloadLinkIsLoading = false;
    });
  }

  // private submitToServerForExcel(student: Student[], selectedCat: any,categoryRes: any,math:any,analogiya:any,okuuTushunuu:any,grammatika:any) {

  //   this.downloadLinkIsLoading = true;
  //   this.apiClientService.post(this.generateExcelLink, {student, selectedCategory: selectedCat,categoryRes,math,analogiya,okuuTushunuu,grammatika}, {
  //     responseType: 'blob',
  //     contentType: 'application/zip'
  //   }).subscribe((data: any) => {
  //     saveAs(data, `Жыйынтык-${selectedCat[0].answerKeyNumber}.zip`);
  //     this.mainPageLink = data.link;
  //     this.downloadLinkIsLoading = false;
  //   });
  // }

  buildForm() {
    this.examsForm = this.fb.group({  
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),Validators.minLength(10),Validators.maxLength(10)]]  
    })  

    this.examsForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    if (!this.examsForm) {
      return;
    }
    const form = this.examsForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'phone')) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key)) {
                this.formErrors[field] += `${(messages as { [key: string]: string })[key]} `;
              }
            }
          }
        }
      }
    }
  }

}
