export class GeneralTest {
  math: any = new Array<string>(60);
  analogiya: any[] = new Array<string>(30);
  okuuTushunuu: any[] = new Array<string>(30);
  grammatika: any[] = new Array<string>(30);
  mathTotal?: number;
  analogiyaTotal?: number;
  okuuTushunuuTotal?: number;
  grammatikaTotal?: number;
}

export class IntensiveTest {
  jooptor: any[] = new Array<string>(30);
}

export class AnswerKey {
  testNumber: number;
  testName: string;
  categoryId: string;
  kg: GeneralTest = new GeneralTest();
  ru: GeneralTest = new GeneralTest();
}

export class AnswerKeyIntensive {
  testNumber: number;
  testName: string;
  kg: IntensiveTest = new IntensiveTest();
  ru: IntensiveTest = new IntensiveTest();
}
