import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AnswerKeyRoutingModule} from './answer-key-routing.module';
import {CreateAnswerKeyComponent} from './create-answer-key/create-answer-key.component';
import {FormsModule} from '@angular/forms';
import {ListAnswerKeyComponent} from './list-answer-key/list-answer-key.component';
import {EditAnswerKeyComponent} from './edit-answer-key/edit-answer-key.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AnswerKeyRoutingModule
  ],
  declarations: [CreateAnswerKeyComponent, ListAnswerKeyComponent, EditAnswerKeyComponent]
})
export class AnswerKeyModule {
}
