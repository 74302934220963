import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamResultsService {
  path = 'exam-results';

  constructor(private http: HttpClient) {
  }

  getByExamId(examId): any {
    return this.http.get<any[]>(`${environment.baseURL}/api/v1/${this.path}/examId/${examId}`,
    {responseType: 'json', observe: 'body'}).toPromise();
  }

  query(body): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseURL}/api/v1/${this.path}/query`, body);
  }

  create(obj: any): any {
    // console.log(`${environment.baseURL}/api/v1/${this.path}`)
    return this.http.post<any>(
      `${environment.baseURL}/api/v1/${this.path}`,
      obj,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }

  remove(id: any): any {
    return this.http.delete<any>(
      `${environment.baseURL}/api/v1/${this.path}/${id}`,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }
}
