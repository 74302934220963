import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentsResultsService {
  path = 'student-results';

  constructor(private http: HttpClient) {
  }

  query(body): Observable<any[]> {
    return this.http.post<any[]>(`${environment.baseURL}/api/v1/${this.path}/query`, body);
  }

  create(obj: any): any {
    // Remove unused fields and store
    obj.answers = undefined;
    obj.points.math = undefined;
    obj.points.analogiya = undefined;
    obj.points.okuuTushunuu = undefined;
    obj.points.grammatika = undefined;

    // console.log(`${environment.baseURL}/api/v1/${this.path}`)

    return this.http.post<any>(
      `${environment.baseURL}/api/v1/${this.path}`,
      obj,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }

  removeByExamId(examId): any {
    return this.http.delete<any[]>(`${environment.baseURL}/api/v1/${this.path}/examId/${examId}`).toPromise();
  }

  removeById(examId): any {
    return this.http.delete<any[]>(`${environment.baseURL}/api/v1/${this.path}/${examId}`).toPromise();
  }

  update(studentResult: any): any {
    return this.http.put<any[]>(`${environment.baseURL}/api/v1/${this.path}`, studentResult).toPromise();
  }
}
