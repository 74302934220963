import {Component, OnInit} from '@angular/core';
import {AnswerKey} from '../../models/answer-key';
import {NotifyService} from '../../core/notify.service';
import {AnswerKeyService} from '../../shared/answer-key.service';
import {Location} from '@angular/common';

@Component({
  selector: 'create-answer-key',
  templateUrl: './create-answer-key.component.html',
  styleUrls: ['./create-answer-key.component.scss']
})
export class CreateAnswerKeyComponent implements OnInit {
  isLoading = false;
  answerKey: AnswerKey = new AnswerKey();

  constructor(private notify: NotifyService,
              private _location: Location,
              private answerKeyService: AnswerKeyService) {
  }

  onSelect(variant: string, lang: string, testType: string, index: any): void {
    this.answerKey[lang][testType][index] = variant;
  }

  ngOnInit() {

  }

  save() {
    this.isLoading = true;
    this.answerKeyService.createAnswerKey(this.answerKey)
      .then((result) => {
        this.isLoading = false;
        this.notify.update('Жооптор сакталды.', 'success');
        this._location.back();
      }).catch((error) => {
      console.log(error);
    });
  }

  onCancel() {
    this._location.back();
  }
}
