import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  branchesCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.branchesCollection = this.afs.collection('branches');
  }

  getData(): Observable<any[]> {
    return this.branchesCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return {id: a.payload.doc.id, ...data};
        });
      })
    );
  }

  getBranch(id: string) {
    return this.afs.doc<any>(`branches/${id}`);
  }

  createBranch(data) {
    const branch = JSON.parse(JSON.stringify(data));
    return this.branchesCollection.add(branch);
  }

  updateBranch(id: string, data: any) {
    return this.getBranch(id).update(data);
  }

  deleteBranch(id: string) {
    return this.getBranch(id).delete();
  }
}
