import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../../core/notify.service';
import {Location} from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

import {Students} from '../../models/students';
import { MineGroupsService } from '../../services/mine/mine-groups.service';
import { MineStudentsService } from '../../services/mine/mine-students.service';
import { MineBranchService } from 'src/app/services/mine/mine-branch.service';
import { MineLanguageService } from 'src/app/services/mine/mine-language.service';

@Component({
  selector: 'reg-edit',
  templateUrl: './reg-edit.component.html',
  styleUrls: ['./reg-edit.component.css']
})
export class RegEditComponent implements OnInit {

  students: Students;
  dupStudents: any;
  branches: any;
  selectedBranch: any = {uuid: ''};
  languages: any;
  selectedLanguage: any = {uuid: ''};
  group: any;
  groups: any;
  selectedGroup: any = {uuid: ''};
  isLoading = false;

  uuid = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private notify: NotifyService,
    // private mineLanguageService: MineLanguageService,
    // private mineBranchesService: MineBranchService,
    private mineGroupsService: MineGroupsService,
    private mineStudentsService: MineStudentsService
  ) { 
    this.students = new Students();
    this.dupStudents = JSON.parse(JSON.stringify(this.students));
  }

  ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.uuid = params.id;

      if (this.uuid) {
          this.mineGroupsService.queryGroup({}).toPromise()
          .then((data)=>{
            this.groups = data;

            this.mineStudentsService.queryStudents({'uuid':this.uuid}).toPromise()
              .then((data1)=> {
                  this.students = data1[0];
                  this.dupStudents = JSON.parse(JSON.stringify(this.students));

                  this.selectedGroup = this.groups.find((gr) => {
                      return this.students.groupUuid === gr.uuid;
                  })    

              })
              .catch((e)=>console.error(e));
          })
          .catch()
          
      } // if case

    });
  }

  save(){

    this.isLoading = true;

    this.dupStudents.name = this.students.name;
    this.dupStudents.surname = this.students.surname;
    this.dupStudents.phone = this.students.phone;
    this.dupStudents.school = this.students.school;

    this.mineStudentsService.updateStudents(this.dupStudents)
    .then((result) => {
      
      if(this.students.groupUuid != this.dupStudents.groupUuid){

        this.mineGroupsService.queryGroup({'uuid':result.groupUuid}).toPromise()
        .then((myGroup)=> {

          this.mineGroupsService.updateGroup({...myGroup[0],'totalStudentsInGroup':Number(myGroup[0].totalStudentsInGroup) + 1})
          .then((result1) => {
            // console.log('result: ',result)
          }).catch();

        });

        this.mineGroupsService.queryGroup({'uuid':this.students.groupUuid}).toPromise()
        .then((yourGroup)=> {

          this.mineGroupsService.updateGroup({...yourGroup[0],'totalStudentsInGroup':Number(yourGroup[0].totalStudentsInGroup) - 1})
          .then((result2) => {
            // console.log('result: ',result)
          }).catch();

        });

      }

      this.isLoading = false;
      this.notify.update('Студент сакталды.', 'success');
      this.router.navigateByUrl("/show/"+result.uuid+"/"+result.groupUuid,{state:{id:result.uuid,groupUuid:result.groupUuid}});    

    }).catch((error) => {
      console.log(error);
      this.notify.update('Студент сакталбады.' + error, 'error');
    });
  }

  onChangeGroup() {
    this.dupStudents.groupUuid = this.selectedGroup.uuid;
    this.dupStudents.groupTitle = this.selectedGroup.title;
  }

  onCancel() {
    this._location.back();
  }
  
}
