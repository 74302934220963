import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AnswerKeyService {

  answerKeyCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore) {
    this.answerKeyCollection = this.afs.collection('answerkeys');
  }

  getData() {
    return this.answerKeyCollection.snapshotChanges().pipe(
      map((actions) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          return {id: a.payload.doc.id, ...data};
        });
      })
    );
  }

  getAnswerKey(id: string) {
    return this.afs.doc<any>(`answerkeys/${id}`);
  }

  createAnswerKey(data) {
    const answerKey = JSON.parse(JSON.stringify(data));
    return this.answerKeyCollection.add(answerKey);
  }

  updateAnswerKey(id: string, data: any) {
    return this.getAnswerKey(id).update(data);
  }

  deleteNote(id: string) {
    return this.getAnswerKey(id).delete();
  }
}
