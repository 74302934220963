import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MineLanguageService {
  path = 'languages';
  // baseUrl = 'http://localhost:3001';
  baseUrl = environment.baseURL;  

  constructor(private http: HttpClient) {
  }

  queryLanguage(body): Observable<any[]> {
    return this.http.post<any[]>(this.baseUrl + `/api/v1/${this.path}/query`, body);
  }

  createLanguage(obj: any): any {

    // console.log('obj: ',obj)

    // console.log(this.baseUrl + `/api/v1/${this.path}`)

    return this.http.post<any>(
      this.baseUrl + `/api/v1/${this.path}`,
      obj,
      {responseType: 'json', observe: 'body'}
    ).toPromise();
  }
}