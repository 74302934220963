import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../../core/notify.service';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import { v4 } from 'uuid';

import {Students} from '../../models/students';
import { MineGroupsService } from '../../services/mine/mine-groups.service';
import { MineStudentsService } from '../../services/mine/mine-students.service';

@Component({
  selector: 'reg',
  templateUrl: './reg.component.html',
  styleUrls: ['./reg.component.css']
})
export class RegComponent implements OnInit {

  students: Students;
  dupStudents: any;
  groups: any;
  selectedGroup: any;
  isLoading = false;
  uuid = '';

  constructor(
    private router: Router,
    private _location: Location,
    private notify: NotifyService,
    private mineGroupsService: MineGroupsService,
    private mineStudentsService: MineStudentsService
  ) { 
    this.students = new Students();
    this.dupStudents = JSON.parse(JSON.stringify(this.students));
  }

  ngOnInit(): void {
    this.groups = this.mineGroupsService.queryGroup({});
  }

  save(){
    
    this.dupStudents.uuid = v4();
    this.isLoading = true;

    this.dupStudents.name = this.students.name;
    this.dupStudents.surname = this.students.surname;
    this.dupStudents.phone = this.students.phone;
    this.dupStudents.school = this.students.school;
    
    this.mineStudentsService.createStudents(this.dupStudents)
    .then((result) => {

      this.isLoading = false;
      this.mineGroupsService.queryGroup({'uuid':result.groupUuid}).toPromise()
      .then((myGroup)=> {

        this.mineGroupsService.updateGroup({...myGroup[0],'totalStudentsInGroup':Number(myGroup[0].totalStudentsInGroup) + 1})
        .then((result1) => {
          
          this.notify.update('Студент сакталды.', 'success');
          this.router.navigateByUrl("/show/"+result.uuid+"/"+result.groupUuid,{state:{id:result.uuid,groupUuid:result.groupUuid}});
        }).catch();

      });

    }).catch((error) => {
      console.log(error);
      this.notify.update('Студент сакталбады.' + error, 'error');
    });
  }

  onChangeGroup() {
    this.dupStudents.groupUuid = this.selectedGroup.uuid;
    this.dupStudents.groupTitle = this.selectedGroup.title;
  }

  onCancel() {
    this._location.back();
  }

}
