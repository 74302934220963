import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MineStudentsService } from 'src/app/services/mine/mine-students.service';
// import {NotifyService} from '../../core/notify.service';
import { MineGroupsService } from 'src/app/services/mine/mine-groups.service';

@Component({
  selector: 'reg-show',
  templateUrl: './reg-show.component.html',
  styleUrls: ['./reg-show.component.css']
})
export class RegShowComponent implements OnInit {

  students: any;
  selectedStudentsUuid = '';
  groups: any;

  isModalActive: boolean = false;
  isLoading = false;
  uuid = '';
  myGroupUuid = '';
  // studentState = { id: '', groupUuid: '' };

  groupTitle = '';
  groupUuid = '';
  branchTitle = '';
  languageTitle = '';

  studentUuid = '';
  studentName = '';
  studentSurname = '';
  studentPhone = '';
  studentSchool = '';

  constructor(
    // private router: Router,
    private route: ActivatedRoute,
    // private notifyService: NotifyService,
    private mineStudentsService: MineStudentsService,
    private mineGroupsService: MineGroupsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.uuid = params.id;
      this.myGroupUuid = params.groupUuid;
      if (this.uuid) {

        this.students = this.mineStudentsService.queryStudents({'uuid':this.uuid}).toPromise()
          .then((data)=>{
            console.log('students: ',data)
            this.studentUuid = data[0].uuid;
            this.studentName = data[0].name;
            this.studentSurname = data[0].surname;
            this.studentPhone = data[0].phone;
            this.studentSchool = data[0].school;
            return data;
          })
          .catch()
        
        this.groups = this.mineGroupsService.queryGroup({'uuid':this.myGroupUuid}).toPromise()
        .then((data)=>{
          console.log('groups: ',data)
          this.groupTitle = data[0].title;
          this.groupUuid = data[0].uuid;
          this.branchTitle = data[0].branchTitle;
          this.languageTitle = data[0].languageTitle;
          return data;
        })
        .catch();
      }
    });
  }

  // goToEdit(){
  //   this.router.navigateByUrl('/edit',{state:{id:this.studentUuid,groupUuid:this.groupUuid}})
  // }

}
