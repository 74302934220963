import {Component, OnInit} from '@angular/core';
import {AnswerKeyService} from '../../shared/answer-key.service';
import {AnswerKey} from '../../models/answer-key';
import {NotifyService} from '../../core/notify.service';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'edit-answer-key',
  templateUrl: './edit-answer-key.component.html',
  styleUrls: ['./edit-answer-key.component.scss']
})
export class EditAnswerKeyComponent implements OnInit {
  isLoading = false;
  answerKey: AnswerKey;
  id = '';

  constructor(private answerKeyService: AnswerKeyService,
              private route: ActivatedRoute,
              private _location: Location,
              private notify: NotifyService) {
  }

  onSelect(variant: string, lang: string, testType: string, index: any): void {
    // console.log('selected: ', variant, lang, index);
    this.answerKey[lang][testType][index] = variant;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      // console.log(params);
      this.id = params.id;
      if (this.id) {
        this.answerKeyService.getAnswerKey(this.id).valueChanges().subscribe((data) => {
          this.answerKey = data;
          // console.log(this.answerKey);
        });
      }
    });


  }

  saveUpdate() {
    // console.log(this.answerKey);
    this.isLoading = true;
    this.answerKeyService.updateAnswerKey(this.id, this.answerKey)
      .then((result) => {
        this.isLoading = false;
        this.notify.update('Жооптор сакталды.', 'success');
        this._location.back();
        // console.log(result);
      }).catch((error) => {
      console.log(error);
    });
  }

  onCancel() {
    this._location.back();
  }

}
