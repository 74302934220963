import { NgModule } from '@angular/core';
// import { AuthService } from './auth.service';
// import { AuthGuard } from './auth.guard';
import { NotifyService } from './notify.service';

// @NgModule({
//   providers: [AuthService, AuthGuard, NotifyService]
// })
@NgModule({
  providers: [NotifyService]
})
export class CoreModule { }
