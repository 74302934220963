import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
// import { map, catchError} from 'rxjs/operators';

// import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamsService {

  // baseURL: string = environment.baseURL;
  // baseURL: string = 'http://localhost:3000';
  // baseURL: string = 'http://localhost:4000';
  baseURL: string = 'https://ort-api.secom.kg';
  path = 'examsresults';
 
  constructor(private http: HttpClient) {
  }
 
  getExam(phone: string): Observable<any> {
    return this.http.get(this.baseURL + '/api/v1/examsresult/' + phone)
  }

  getExams(phone: string): any {
    return this.http.get(this.baseURL + '/api/v1/examsresult/' + phone, {responseType: 'json', observe: 'body'} ).toPromise()
  }

  query(body:{phone:string}): Observable<any[]> {
    return this.http.post<any[]>(this.baseURL +`/api/v1/${this.path}/`+ body.phone +`/query`, body);
  }
}
