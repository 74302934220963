import {Component, OnInit} from '@angular/core';
import {AnswerKeyService} from '../../shared/answer-key.service';
import {NotifyService} from '../../core/notify.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'list-answer-key',
  templateUrl: './list-answer-key.component.html',
  styleUrls: ['./list-answer-key.component.scss']
})
export class ListAnswerKeyComponent implements OnInit {
  isLoading = false;
  answerKeys: any;
  isModalActive = false;
  selectedAnswerKeyId = '';

  constructor(private answerKeyService: AnswerKeyService,
              private router: Router,
              private route: ActivatedRoute,
              private notify: NotifyService) {
  }

  ngOnInit() {
    this.answerKeys = this.answerKeyService.getData();
  }

  closeModal() {
    this.isModalActive = false;
  }

  showPopup(answerKeyId) {
    this.selectedAnswerKeyId = answerKeyId;
    this.isModalActive = !this.isModalActive;
  }

  delete() {
    this.isLoading = true;
    this.closeModal();
    this.answerKeyService.deleteNote(this.selectedAnswerKeyId)
      .then((result) => {
        this.isLoading = false;
        this.notify.update('Жооптор өчүрүлдү.', 'success');
      }).catch((error) => {
      console.log(error);
    });
  }

}
