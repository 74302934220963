import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {
    // baseURL: string = environment.baseURL;
    // baseURL: string = 'http://localhost:3001';
    baseURL: string = 'https://ort-api.secom.kg';

  constructor(private http: HttpClient) {
  }

  makeRequest(method, url, body, headers) {
    const httpOptions: any = {'Content-Type': 'application/json'};
    if (headers) {
      if (headers.contentType) {
        httpOptions['Content-Type'] = headers.contentType;
      }
      if (headers.responseType) {
        httpOptions.responseType = headers.responseType;
      }
    }

    const httpHeaders = new HttpHeaders(httpOptions);

    const options: any = {
      body,
      headers: httpHeaders
    };
    return this.http.request(method, `https://ort-api.secom.kg/${url}`, options)
      .pipe(
        catchError(err => {
          console.log(err);
          return err;
        })
      );
  }

  get(url, params, headers?) {
    // console.log('makeRequest:')
    return this.makeRequest('GET', url, null, headers);
  }

  post(url, body, headers?) {
    // console.log('student and selectedCategory: ',body);
    return this.http.post(this.baseURL + `/${url}`, body, headers);
  }

  delete(url, headers) {
    return this.makeRequest('DELETE', url, null, headers);
  }

  update(url, body, params = {}) {
    return this.makeRequest('PUT', url, body, params);
  }

  put(url, body, params) {
    return this.makeRequest('PUT', url, body, params);
  }
}


