import {Component, OnInit} from '@angular/core';
import {ParseGeneralService} from './parse-general.service';
import {Student} from '../../models/student';
import {AnswerKeyService} from '../../shared/answer-key.service';
import {StudentService} from '../../shared/student.service';
import {ApiClientService} from '../../shared/api-client.service';
import saveAs from 'file-saver';
import {CategoryService} from '../../shared/category.service';
import {AuthService} from '../../core/auth.service';
import {ExamResult} from '../../models/exam-result.model';
import {StudentsResultsService} from '../../services/students-results.service';
import {NotifyService} from '../../core/notify.service';
import {ExamResultsService} from '../../services/exam-results.service';

@Component({
  selector: 'general-check',
  templateUrl: './general-check.component.html',
  styleUrls: ['./general-check.component.scss']
})
export class GeneralCheckComponent implements OnInit {

  generateExcelLink = 'api/generate-general-excel';
  file: any;
  fileData: any;
  students: Student[] = [];

  mainPageLink = '';
  downloadLinkIsLoading = false;
  documentSubmitted = false;
  predmetter = ['math', 'analogiya', 'okuuTushunuu', 'grammatika'];

  answerKeys: any = [];
  selectedAnswerKey: any;
  selectedCategory: any;
  publishResults = false;
  currentUser: any;
  allExistingStudents: any;
  examResult = new ExamResult();

  constructor(private auth: AuthService,
              private parserService: ParseGeneralService,
              private answerKeyService: AnswerKeyService,
              private studentService: StudentService,
              private examResultsService: ExamResultsService,
              private categoryService: CategoryService,
              private apiClientService: ApiClientService,
              private studentsResultsService: StudentsResultsService,
              private notifyService: NotifyService,
  ) {
  }

  ngOnInit() {
    this.auth.user.subscribe((data) => {
      if (data) {
        this.currentUser = data;
      }
    });

    this.answerKeys = this.answerKeyService.getData();
  }

  fileChanged(e) {
    this.file = e.target.files[0];
  }

  isValid() {
    return this.file && this.selectedAnswerKey;
  }

  uploadDocument() {

    this.downloadLinkIsLoading = true;
    this.documentSubmitted = true;
    const fileReader = new FileReader();
    fileReader.readAsText(this.file);

    fileReader.onload = (e) => {
      this.parseFileContent(fileReader.result.toString());
    };
  }

  /**
   * @param result
   * Parse file contents and produce students[] object.
   */
  private parseFileContent(result: string) {
    this.fileData = this.parserService.parse(result);
    this.fileData = this.fileData.filter((data) => data.length > 0);
    this.students = this.fileData.map((row) => this.studentService.generateStudent(row));

    // console.log('selectedAnswerKey in general: ',this.selectedAnswerKey);

    this.students = this.studentService.evaluateTests(this.students, this.selectedAnswerKey);

    this.categoryService
      .getCategory(this.selectedAnswerKey.categoryId)
      .valueChanges()
      .subscribe((data) => {
        this.selectedCategory = data;
        const resStudents = this.evaluateByCategory(this.students);

        if (this.publishResults) {
          const examResult = this.examResult;
          examResult.displayName = this.selectedAnswerKey.testNumber + '-' + this.selectedAnswerKey.testName;
          examResult.branchId = this.currentUser.branchId;
          examResult.createdAt = new Date();
          let fails = 0;
          let success = 0;
          
          // console.log('examResult: ',examResult);

          this.examResultsService.create(examResult).then((createdExam) => {
            // console.log('createdExam: ',createdExam);
            this.students.map((student) => {
              student.examUuid = createdExam._id;
              student.selectedAnswerKeyId = this.selectedAnswerKey.id;
              this.studentsResultsService.create(student)
                .then((st) => {
                  // console.log('created: ', st);
                  success++;
                }).catch((err) => {
                console.log(err);
                fails++;
              });
              // TODO: we should disable this
              // this.studentService.createStudent(student).then((st) => console.log('created: ', st));
            });

            // if (fails > 0) {
            //   this.notifyService.update(`Total success ${success}, failed: ${fails}. Try again!!!`, 'error');
            // } else {
            //   this.notifyService.update(`Total success ${success}, failed: ${fails}`, 'info');
            // }

          });
        }

        this.submitToServer(resStudents, this.selectedCategory);
      });

  }

  private submitToServer(students: Student[], selectedCat: any) {
    this.downloadLinkIsLoading = true;

    this.apiClientService.post(this.generateExcelLink, {students, selectedCategory: selectedCat}, {
      responseType: 'blob',
      contentType: 'application/zip'
    }).subscribe((data: any) => {
      saveAs(data, `ЖРТ-${this.selectedAnswerKey.testNumber}-${this.selectedAnswerKey.testName}.zip`);
      this.mainPageLink = data.link;
      this.downloadLinkIsLoading = false;
    });
  }


  private evaluateByCategory(students: Student[]) {
    const evaluatedStudents = students.map((student) => {
      const group = (student.group === 'K') ? 'kg' : 'ru';
      const studentCategory = JSON.parse(JSON.stringify(this.selectedCategory[group]));
      this.predmetter.forEach((boluk) => {
        Object.keys(this.selectedCategory[group][boluk]).forEach((predmet) => {
          this.selectedCategory[group][boluk][predmet].subCategories.forEach((cat, catInd) => {
            let total = 0;
            let correct = 0;
            cat.questions.forEach((val, ind) => {
              if (!cat.questions[ind] || !cat.questions[ind].length) {
                return;
              }
              total++;
              if (student.points[boluk][val - 1] === '1' || student.points[boluk][val - 1] === 1) {
                correct++;
              }
            });

            studentCategory[boluk][predmet].subCategories[catInd].questions = undefined;
            studentCategory[boluk][predmet].subCategories[catInd].total = total;
            studentCategory[boluk][predmet].subCategories[catInd].correct = correct;

          });
        });
      });

      student.categoryRes = studentCategory;
      student.createdAt = new Date();

      // points evaluation started
      const mathsEvaluate = [1, 1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28, 29, 30, 31, 32, 34, 35, 36, 37, 38, 39, 40, 41, 42, 44, 45, 46, 47, 48, 49, 50, 51, 52, 54, 55, 56, 57, 58, 59, 60, 61, 63, 64, 65, 66, 67];
      const analogiyaEvaluate = [5, 8, 10, 12, 13, 16, 17, 19, 21, 23, 25, 27, 29, 30, 33, 34, 36, 38, 40, 42, 44, 45, 48, 49, 51, 53, 55, 57, 59, 61, 63];
      const okuuTushunuuEvaluate = [5, 7, 8, 10, 12, 14, 16, 17, 20, 21, 23, 25, 26, 29, 30, 32, 34, 35, 38, 39, 41, 43, 44, 47, 48, 50, 52, 54, 56, 57, 58];
      const grammatikaEvaluate = [5, 6, 7, 8, 10, 12, 14, 16, 17, 19, 21, 23, 25, 26, 28, 30, 31, 34, 35, 37, 39, 40, 42, 44, 46, 48, 49, 51, 53, 55, 57];

      student.mathsTotalPoints = mathsEvaluate[student.points.mathTotal];
      student.analogiyaTotalPoints = analogiyaEvaluate[student.points.analogiyaTotal];
      student.okuuTotalPoints = okuuTushunuuEvaluate[student.points.okuuTushunuuTotal];
      student.grammatikaTotalPoints = grammatikaEvaluate[student.points.grammatikaTotal];
      student.totalPoints = student.mathsTotalPoints + student.analogiyaTotalPoints + student.okuuTotalPoints + student.grammatikaTotalPoints;
      // points evaluation ends

      return student;
    });

    return evaluatedStudents;
  }
}
