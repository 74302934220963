import { GeneralTest } from './answer-key';

export class Student {
  id: string;
  name = '';
  surname = '';
  phone = '';
  examUuid = '';
  selectedAnswerKeyId = '';
  createdAt: Date;
  createdAtString = '';
  registrationNumber = '';
  categoryRes: any = {};
  testNumber = '';
  schoolNumber = '';
  group = 'K'; // kyrgyz group by default
  answers: GeneralTest = new GeneralTest();
  points: GeneralTest = new GeneralTest();
  totalPoints: number;
  mathsTotalPoints: number;
  analogiyaTotalPoints: number;
  okuuTotalPoints: number;
  grammatikaTotalPoints: number;
}

export class IntenseStudent {
  name = '';
  surname = '';
  parentsPhone = '';
  parentsNameSurname = '';
  studentsPhone = '';
  schoolNumber = '';
  group = '';
  answers = '';
  points = '';
  totalPoints = 0;
  oblast = '';
  rayon = '';
}

export const OpticReaderConf = {
  surname: 19,
  name: 13,
  phone: 10,
  registrationNumber: 6,
  testNumber: 2,
  schoolNumber: 3,
  group: 1,
  math: 60,
  analogy: 30,
  reading: 30,
  grammar: 30,
};
